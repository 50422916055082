import React from "react";
import { Link } from "gatsby";
import { CgClose } from "@react-icons/all-files/cg/CgClose";
// import * as Scroll from "react-scroll";
// import { Link } from "react-scroll";
import OverlayIndexSort from "../components/overlayIndexSort";
import "../styles/overlayIndex.scss";

const OverlayIndex = ({
  indexOpen,
  setIndexOpen,
  projectData,
  indexData,
  inlineBG,
  updateSort,
  setRandomSort,
}) => {
  const projectIndex = (
    <>
      {projectData.map((project) => (
        <>
          <Link
            key={`d-indexlist-${project._id}`}
            to={`#${project.slug.current}`}
            className={`desktop flex-index index-item d-indexlist-${project._id}`}
          >
            <div className="date">{project.year}</div>
            <div className="title">{project.name}</div>
            <div className="credit">{project.client}</div>
            <div className="">
              {project.projecttype.map((type, i) => (
                <span
                  key={`${project._id}-type-${i}`}
                  className={`${project._id}-type-${i}`}
                >
                  &bull;&nbsp;{type}{" "}
                </span>
              ))}
            </div>
          </Link>
        </>
      ))}
    </>
  );
  const fullIndex = (
    <div className="index-full flex col">
      <div className="projects-index flex col">
        <div className="projects-index-header">
          <button
            className="button-m-close"
            onClick={() => {
              setIndexOpen(false);
            }}
          >
            <CgClose />
          </button>
          <h2 className="parens text-lrg">Projects Index</h2>
        </div>
        <ul>
          {projectData.map((project) => (
            <li
              key={`m-indexlist-${project._id}`}
              className={`flex-index m-indexlist-${project._id}`}
            >
              <Link
                onClick={() => {
                  setIndexOpen(false);
                }}
                to={`#${project.slug.current}`}
                className="flex-index index-item"
              >
                <div className="title">{project.name}</div>
                <div className="date">{project.year}</div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
  return (
    <div
      className={`overlay-index flex col${indexOpen ? ` open` : ""}`}
      style={inlineBG}
    >
      <div className={`individual-wrapper relative selected flex col`}>
        <div className={`index-individual projects selected`}>
          <div className="index-head flex-index" style={inlineBG}>
            <div className="date">Date</div>
            <div className="title">Title</div>
            <div className="credit">
              For/With
              {/* {indexData === "projects" ? "For/With" : "Credit"} */}
            </div>
            <div className="type">Type</div>
          </div>
          {projectIndex}
          {/* {indexData === "projects" ? projectIndex : digestIndex} */}
        </div>
      </div>
      <OverlayIndexSort
        indexData={indexData}
        inlineBG={inlineBG}
        updateSort={updateSort}
        setRandomSort={setRandomSort}
      />
      {fullIndex}
    </div>
  );
};

export default OverlayIndex;
