import * as React from "react";
// import Draggable from "react-draggable";
import { useEffect, useRef } from "react";
import DigestItem from "./digestItem";
import "../styles/digest.scss";

const Digest = ({
  digestReady,
  pageLoaded,
  digestContent,
  digestOpen,
  setDragging,
  mobileState,
  digestStyle,
  toggleDigest,
}) => {
  // const [digestOpen, setDigestOpen] = useState(false);

  const digestRef = useRef();

  // Drag functions ---------------------------------------

  let manualDragging;
  let dragStartY, dragStartX;
  let targetStartY, targetStartX;
  let targetEndY, targetEndX;

  const draggableMouseDown = (e) => {
    if (!mobileState) {
      manualDragging = true;
      setDragging(true);
      // console.log("drag mouse down");
      targetStartY = digestRef.current.offsetTop;
      targetStartX = digestRef.current.offsetLeft;
      dragStartY = e.pageY;
      dragStartX = e.pageX;
    }
  };

  useEffect(() => {
    if (!mobileState && window.addEventListener) {
      window.addEventListener("mousemove", (e) => {
        if (!manualDragging) {
          return;
        }

        // console.log("drag mouse move");
        let dY = targetStartY + (e.pageY - dragStartY);
        let dX = targetStartX + (e.pageX - dragStartX);
        let maxY =
          window.innerHeight - digestRef.current.getBoundingClientRect().height;
        let maxX =
          window.innerWidth - digestRef.current.getBoundingClientRect().width;

        if (dY < 0) {
          targetEndY = "0px";
        } else if (dY > 0 && dY < maxY) {
          targetEndY = dY + "px";
        } else if (dY > maxY) {
          targetEndY = maxY + "px";
        }

        if (dX < 0) {
          targetEndX = "0px";
        } else if (dX > 0 && dX < maxX) {
          targetEndX = dX + "px";
        } else if (dX > maxX) {
          targetEndX = maxX + "px";
        }

        digestRef.current.style.top = targetEndY;
        digestRef.current.style.left = targetEndX;
      });

      window.addEventListener("mouseup", () => {
        // console.log("drag mouse up");
        setDragging(false);
        manualDragging = false;
      });
    }
  });

  // const draggableMouseUp = () => {
  // };

  // Load in digest ---------------------------------------

  // useEffect(() => {
  //   const digestSelector = gsap.utils.selector(digestRef);
  //   // setTimeout(() => {
  //   gsap.from(digestSelector(".digest-header, .digest-body"), {
  //     // opacity: 0,
  //     // duration: 0.5,
  //     // delay: 2,
  //     // filter: "blur(0px)",
  //     // stagger: 0.25,
  //   });
  //   // }, 1000);
  // }, [digestReady]);

  return (
    <div
      style={digestStyle}
      ref={digestRef}
      onMouseDown={draggableMouseDown}
      // onMouseMove={draggableMouseMove}
      // onMouseUp={draggableMouseUp}
      className={`main-digest${digestOpen ? " open" : " closed"}${
        pageLoaded && " loaded"
      }`}
    >
      {pageLoaded && (
        <div className="digest-inner no-cursor">
          <div
            className={`digest-header flex-nogap flex-center${
              digestReady && " ready"
            }`}
          >
            <button onClick={toggleDigest}>
              <h2 className="parens-xs">Digest</h2>
            </button>
            {/* <span className="parens-xs digest-toggle" onClick={toggleDigest}>
                {digestOpen ? "Close" : "Open"}
              </span> */}
          </div>
          <div
            className={`digest-body flex-nogap col no-cursor${
              digestReady && " ready"
            }`}
          >
            {digestContent.map((item) => (
              <DigestItem key={item.id} item={item} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Digest;
