import React from "react";
import { useEffect, useRef } from "react";
import { gsap } from "gsap";

const Loading = ({ pageLoaded }) => {
  const loaderRef = useRef();
  const loaderSelector = gsap.utils.selector(loaderRef);

  const loadertl = gsap.timeline({ repeat: 2, repeatDelay: 1 });

  useEffect(() => {
    loadertl.to(loaderSelector("span"), {
      // opacity: 0.5,
      duration: 0.25,
      y: -5,
      yoyo: true,
      stagger: 0.1,
      delay: 0.25,
    });
    loadertl.to(
      loaderSelector("span"),
      {
        // opacity: 0.5,
        duration: 0.25,
        y: 0,
        yoyo: true,
        stagger: 0.1,
      },
      "<0.25"
    );
  }, []);

  return (
    <div
      ref={loaderRef}
      className={`loader padding ${pageLoaded ? "loaded" : "loading"}`}
    >
      <span>L</span>
      <span>o</span>
      <span>a</span>
      <span>d</span>
      <span>i</span>
      <span>n</span>
      <span>g</span>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  );

  // let waveInterval;

  // const hoverWaveStart = () => {
  //   const loader = document.querySelector(".loader");
  //   const chars = loader.querySelectorAll("span");
  //   chars.forEach((char, i) => {
  //     let length = chars.length;
  //     let delay = 75 * i;
  //     let duration = 500;
  //     let postDelay = delay + duration;
  //     setTimeout(() => {
  //       char.classList.add("waving");
  //     }, delay);
  //     setTimeout(() => {
  //       char.classList.remove("waving");
  //     }, postDelay);
  //     // const setHover = () => {
  //     // };
  //     // setHover();
  //   });
  // };

  // const hoverWaveEnd = () => {
  //   // console.log("mouse leave");
  //   clearInterval(waveInterval);
  //   waveInterval = null;
  //   // const chars = e.target.querySelectorAll("span");
  //   // chars.forEach((char, i) => {
  //   //   char.classList.remove("waving");
  //   // });
  // };

  // useEffect(() => {
  //   hoverWaveStart();
  // }, []);
};

export default Loading;
