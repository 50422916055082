import * as React from "react";
import { useRef, useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { useInView } from "react-intersection-observer";

const LightboxMedia = ({
  project,
  swiperActionMouseOver,
  swiperActionMouseOut,
}) => {
  // console.log(project.media);
  // const type = project.mediatype;
  // const image = project.mediatype === "image" && project.images.length === 1;
  // const swiper = project.mediatype === "image" && project.images.length > 1;
  // const video = project.mediatype === "video";
  // const website = project.mediatype === "website";

  const lightboxUpdateHandler = (swiper) => {
    swiper.slideToLoop(0, 0, false);
  };

  // if (project.media.length === 1) {}

  // if (image) {
  //   const image = project.images[0].asset.gatsbyImageData;
  //   return (
  //     <div className={`media media-${type}`}>
  //       <GatsbyImage image={image} alt="" />
  //     </div>
  //   );
  // }
  // if (swiper) {
  // const swiperLength = project.images.length;

  // const allProjectMedia = project.media.map((item) =>
  // {item._key === "image" && (
  //   parseFloat(image.asset.gatsbyImageData.width / image.asset.gatsbyImageData.height)
  // ) : item.aspect}
  // );
  // console.log(allProjectMedia)
  const { ref, inView, entry } = useInView({ threshold: 0.1 });
  const videoRef = useRef();

  const handleExit = () => {
    videoRef?.current?.pause();
  };

  const handleEntry = () => {
    videoRef?.current?.play();
  };

  useEffect(() => {
    if (!inView) {
      handleExit();
    } else {
      handleEntry();
    }
  }, [inView]);

  const allRatios = [];
  const ratio = allRatios.sort()[allRatios.length - 1];

  // const projectImages = project.media.filter((item) => item._type === "image");
  // const ratio = projectImages
  //   .map((image) =>
  //     parseFloat(
  //       image.asset.gatsbyImageData.width / image.asset.gatsbyImageData.height
  //     )
  //   )
  //   .sort()[projectImages.length - 1];

  const swiperStyle = {
    aspectRatio: ratio,
  };
  // const swiper = document.querySelector(".swiper");
  // const slides = document.querySelectorAll();

  // useEffect(() => {
  //   console.log(firstSwiper);
  // }, [firstSwiper]);

  const desktopAspect = `16/10`;

  const lightboxMediaMulti = (
    <>
      <Swiper
        className="lightbox-swiper"
        modules={[Navigation, Pagination, EffectFade]}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log("On swiper")}
        pagination={{
          el: ".lightbox-swiper-pagination",
          type: "custom",
          renderCustom: (swiper, current, total) => {
            return current + " of " + total;
          },
        }}
        speed={500}
        navigation={{ nextEl: ".swiper-next", prevEl: ".swiper-prev" }}
        loop={true}
        allowTouchMove={false}
        grabCursor={false}
        // spaceBetween={16}
        observer={true}
        onObserverUpdate={lightboxUpdateHandler}
        // style={swiperStyle}
      >
        {project.mediagallery2.map((item) => (
          // <p>
          //   {item._type === "image" && "Image"}
          //   {item._type === "video" && "Video"}
          //   {item._type === "website" && "Website"}
          // </p>
          <SwiperSlide ref={ref} key={item._key}>
            {item._type === "imageWithAlt" && (
              <GatsbyImage
                image={item.asset.gatsbyImageData}
                alt={item.alt}
                style={swiperStyle}
              />
            )}
            {item._type === "video" && (
              <video
                ref={videoRef}
                width={`100%`}
                autoPlay
                playsInline
                loop
                muted
              >
                <source
                  src={item.videofile.asset.url}
                  type={`${item._type}/${item.videofile.asset.extension}`}
                />
              </video>
            )}
          </SwiperSlide>
        ))}
        <div
          className="swiper-prev"
          data-action="Prev"
          // onMouseOver={(e) => console.log(e.target.dataset.action)}
          onMouseOver={swiperActionMouseOver}
          onFocus={swiperActionMouseOver}
          onMouseOut={swiperActionMouseOut}
          onBlur={swiperActionMouseOut}
        ></div>
        <div
          className="swiper-next"
          data-action="Next"
          // onMouseOver={(e) => console.log(e.target.dataset.action)}
          onMouseOver={swiperActionMouseOver}
          onFocus={swiperActionMouseOver}
          onMouseOut={swiperActionMouseOut}
          onBlur={swiperActionMouseOut}
        ></div>
      </Swiper>

      <div className="swiper-meta">
        {/* <button className="swiper-prev">&lt; Prev</button> */}
        <div className="lightbox-swiper-pagination"></div>
        {/* <button className="swiper-next">Next &gt;</button> */}
      </div>
    </>
  );

  const lightboxMediaWebsite = (
    <>
      {project.mediatype === "website" &&
        project.mediawebsite2[0]._type === "website" && (
          <div
            className="iframe-container desktop"
            // data-aspect={`${item.aspect}+"%"`}
            style={{
              // paddingTop: 125 + "%",
              aspectRatio: desktopAspect,
            }}
          >
            <iframe
              sandbox
              src={project.mediawebsite2[0].url}
              title={project.name}
              allow="fullscreen"
              allowFullScreen
              frameBorder="0"
              style={{
                aspectRatio: desktopAspect,
              }}
            />
          </div>
        )}
      {project.mediatype === "website" &&
        project.mediawebsite2[0]._type === "websitevideo" && (
          <div className="video-container box flex">
            <video
              width={`100%`}
              autoPlay
              playsInline
              loop
              muted
              style={{
                aspectRatio: desktopAspect,
              }}
            >
              <source
                src={project.mediawebsite2[0].asset.url}
                type={`video/${project.mediawebsite2[0].asset.extension}`}
              />
            </video>
          </div>
        )}
    </>
  );

  return (
    <div
      className={`lightbox-media lightbox-media-${project.mediatype} ${project.slug.current}`}
    >
      <div className={`media-wrapper flex media-wrapper-${project.mediatype}`}>
        {project.mediatype === "image" && lightboxMediaMulti}
        {project.mediatype === "website" && lightboxMediaWebsite}
      </div>
    </div>
  );
  // }
};

export default LightboxMedia;
