import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import "../styles/header.scss";
// import { CSSTransition } from "react-transition-group";

const Header = ({ toggleMenu, pageLoaded }) => {
  // const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [year, setYear] = useState("");
  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  // const [count, setCount] = useState(0)

  // useEffect(() => {}, []);

  setInterval(() => {
    const zeroFill = (n) => {
      return ("0" + n).slice(-2);
    };
    const weekdays = ["Mon", "Tues", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date();
    // const formattedDate =
    //   date.getFullYear() +
    //   " " +
    //   weekdays[date.getDay() - 1] +
    //   " " +
    //   zeroFill(date.getDate()) +
    //   " " +
    //   months[date.getMonth()];
    const year = date.getFullYear();
    const weekday = weekdays[date.getDay() - 1];
    const day = zeroFill(date.getDate());
    const month = months[date.getMonth()];
    var hour = date.getHours();
    // hour = hour == 0 ? 12 : hour > 12 ? hour - 12 : hour;
    const formattedTime =
      hour +
      // zeroFill(hour) +
      ":" +
      zeroFill(date.getMinutes()) +
      ":" +
      zeroFill(date.getSeconds());
    setTime(formattedTime);
    // setDate(formattedDate);
    setYear(year);
    setWeekday(weekday);
    setDay(day);
    setMonth(month);
  }, 1000);

  return (
    <>
      <header className={`col text-lrg oldstyle${pageLoaded && " loaded"}`}>
        <button className="cta menu-open" onClick={toggleMenu}>
          Menu
        </button>
      </header>
      <div
        className={`datetime flex-nogap flex-end text-xs${
          pageLoaded && " loaded"
        }`}
      >
        <div>{year}</div>
        <div>{weekday}</div>
        <div>{day}</div>
        <div>{month}</div>
        {/* {date}
        {time} */}
        {/* <span className="date">{date}</span> */}
        <div className="time">
          <div className="time-inner">{time}</div>
          {/* <div className="time-inner">00:00:00</div> */}
        </div>
        <div>AEST</div>
      </div>
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: `Lloyd MST Portfolio`,
};

export default Header;
