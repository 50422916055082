import * as React from "react";
import { useState } from "react";
import { PortableText } from "@portabletext/react";
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image";

import ProjectMedia from "./media-project";
const Project = ({
  setDigestOpen,
  project,
  index,
  lightboxOpen,
  focusedProject,
  closeLightbox,
  setLightboxOpen,
  setFocus,
  setIndexOpen,
  setMenuOpen,
  setAboutOpen,
  firstSwiper,
  secondSwiper,
  setSwiperCursor,
  setSwiperCursorText,
  swiperActionMouseOver,
  swiperActionMouseOut,
  descriptionOpen,
  setDescriptionOpen,
}) => {
  const [singleDescriptionOpen, setSingleDescriptionOpen] = useState(null);

  const toggleProjectDescription = () => {
    setDescriptionOpen(!descriptionOpen);
  };

  const toggleSingleDescription = (index) => {
    setSingleDescriptionOpen(index);
  };

  const openLightbox = (e) => {
    // console.log("Open lightbox");
    setLightboxOpen(true);
    // setFocus(parseInt(e.target.dataset.id));
    setFocus(project);
    setDescriptionOpen(false);
    setIndexOpen(false);
    setMenuOpen(false);
    setAboutOpen(false);
    setDigestOpen(false);
    // setDigestAway(true);
  };

  const changeLightbox = (e) => {
    // console.log("Change lightbox");
    setDescriptionOpen(false);
    // setFocus(parseInt(e.target.dataset.id));
    setFocus(project);
  };

  const projectIsFocused = focusedProject === project;

  const buttonSeeMore = (
    <>
      <button
        className={`desktop parens gsap`}
        data-id={index}
        onClick={lightboxOpen ? changeLightbox : openLightbox}
      >
        <span>S</span>
        <span>e</span>
        <span>e</span>
        <span>&nbsp;</span>
        <span>m</span>
        <span>o</span>
        <span>r</span>
        <span>e</span>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </button>
    </>
  );

  const buttonClose = (
    <>
      <button
        className="desktop parens gsap"
        data-id={index}
        onClick={closeLightbox}
      >
        <span>C</span>
        <span>l</span>
        <span>o</span>
        <span>s</span>
        <span>e</span>
      </button>
    </>
  );

  const buttonShowDescription = (
    <>
      <button
        className="parens mobile"
        data-id={index}
        onClick={() => toggleSingleDescription(index)}
      >
        <span>S</span>
        <span>e</span>
        <span>e</span>
        <span>&nbsp;</span>
        <span>m</span>
        <span>o</span>
        <span>r</span>
        <span>e</span>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </button>
    </>
  );

  const buttonCloseDescription = (
    <>
      <button
        className="parens"
        data-id={index}
        onClick={() => toggleSingleDescription(null)}
      >
        <span>C</span>
        <span>l</span>
        <span>o</span>
        <span>s</span>
        <span>e</span>
      </button>
    </>
  );

  // console.log(focusedProject);
  // console.log(projectIsFocused);

  return (
    <div className="index-project" id={project.slug.current}>
      <h3>
        {project.name}, <span className="oldstyle">{project.year}</span>
      </h3>
      <ProjectMedia
        project={project}
        firstSwiper={firstSwiper}
        // setFirstSwiper={setFirstSwiper}
        secondSwiper={secondSwiper}
        // setSecondSwiper={setSecondSwiper}
        setSwiperCursor={setSwiperCursor}
        setSwiperCursorText={setSwiperCursorText}
        swiperActionMouseOver={swiperActionMouseOver}
        swiperActionMouseOut={swiperActionMouseOut}
      />
      {project.mediatype === "image" && (
        <div
          className={`project-swiper-pagination project-swiper-pagination-${project._id}`}
        ></div>
      )}
      <div className="credits">
        <ul>
          {project.credits1.map((credit1, i) => (
            <li key={credit1._id}>
              {credit1.crediturl ? (
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href={credit1.crediturl}
                >
                  {credit1.creditname}
                </a>
              ) : (
                <>{credit1.creditname}</>
              )}
            </li>
          ))}
        </ul>
        <ul>
          {project.credits2.map((credit2, i) => (
            <li key={credit2._id}>
              {credit2.crediturl ? (
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href={credit2.crediturl}
                >
                  {credit2.creditname}
                </a>
              ) : (
                <>{credit2.creditname}</>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="text-xs">
        {project.projecttype.map((type) => (
          <span key={type}>&bull;&nbsp;{type} </span>
        ))}
      </div>
      {lightboxOpen && projectIsFocused ? buttonClose : ""}
      {lightboxOpen && !projectIsFocused ? buttonSeeMore : ""}
      {!lightboxOpen ? buttonSeeMore : ""}
      {singleDescriptionOpen !== index ? (
        buttonShowDescription
      ) : (
        <div className="project-description mobile flex col">
          {/* <p>{project.description}</p> */}
          <PortableText value={project._rawDescription} />
          {buttonCloseDescription}
        </div>
      )}
    </div>
  );
};

export default Project;
