import * as React from "react";
import { useState, useRef } from "react";
// import { BlockContent } from "block-content-to-react";
// const BlockContent = require("@sanity/block-content-to-react");
import { PortableText } from "@portabletext/react";
import "../styles/nav.scss";
import { gsap } from "gsap";
import { CgClose } from "@react-icons/all-files/cg/CgClose";
// import OverlayIndex from "../components/overlayIndex"

const components = {
  marks: {
    link: ({ children, value }) => {
      return (
        <a href={value.href} target="_blank" rel="noreferrer noopener">
          {children}
        </a>
      );
    },
  },
};

const Nav = ({
  fullHeightInlineBG,
  fullHeight,
  colors,
  menuOpen,
  aboutOpen,
  toggleMenu,
  toggleAbout,
  menuToggleDigest,
  closeAbout,
  toggleFont,
  toggleBG,
  inlineBG,
  setRandomBG,
  setRandomFont,
  openIndex,
  about,
  aboutcredits1,
  aboutcredits2,
  updateSort,
}) => {
  const [colorsOpen, setColorsOpen] = useState(false);
  const [fontsOpen, setFontsOpen] = useState(false);

  const colorsList = useRef();
  const colorsListSelector = gsap.utils.selector(colorsList);
  const fontsList = useRef();
  const fontsListSelector = gsap.utils.selector(fontsList);

  const listStagger = 0.04;

  const toggleColorsList = () => {
    if (colorsOpen) {
      setColorsOpen(false);
      gsap.to(colorsListSelector("div li"), {
        display: "none",
        stagger: -listStagger,
        // height: 0,
        duration: 0.3,
        // ease: "power3.out",
        ease: "none",
      });
    } else {
      setColorsOpen(true);
      gsap.to(colorsListSelector("div li"), {
        display: "block",
        stagger: listStagger,
        // height: "auto",
        duration: 0.3,
        // ease: "power3.out",
        ease: "none",
      });
    }
    // setColorsOpen(!colorsOpen);
    setFontsOpen(false);
  };

  const toggleFontsList = () => {
    if (fontsOpen) {
      setFontsOpen(false);
      gsap.to(fontsListSelector("div li"), {
        display: "none",
        stagger: -listStagger,
        // height: 0,
        duration: 0.3,
        // ease: "power3.out",
        ease: "none",
      });
    } else {
      setFontsOpen(true);
      gsap.to(fontsListSelector("div li"), {
        display: "block",
        stagger: listStagger,
        // height: "auto",
        duration: 0.3,
        // ease: "power3.out",
        ease: "none",
      });
    }

    // setFontsOpen(!fontsOpen);
    setColorsOpen(false);
  };

  return (
    <div
      className={`nav-wrapper text-lrg flex${menuOpen ? " menu-open" : ""}${
        aboutOpen ? " about-open" : ""
      }`}
      // className={`nav-wrapper flex open`}
      style={fullHeight}
    >
      <div className={`nav flex col`} style={inlineBG}>
        <button className="menu-close cta" onClick={toggleMenu}>
          Close
        </button>
        <nav>
          <ol className="flex col">
            <div>
              <li>
                <button onClick={openIndex} data-indexdata="projects">
                  Projects
                </button>
              </li>
              <li>
                <button onClick={menuToggleDigest} data-indexdata="digest">
                  Digest
                </button>
              </li>
              <li>
                <button onClick={toggleAbout}>Connection</button>
              </li>
            </div>
            <div>
              <li>
                <button data-sort="random" onClick={updateSort}>
                  Random
                </button>
              </li>
              <li>
                <button data-sort="name" onClick={updateSort}>
                  A-B
                </button>
              </li>
              <li>
                <button data-sort="year" onClick={updateSort}>
                  Latest
                </button>
              </li>
            </div>
            <div>
              <li
                ref={fontsList}
                className={`fonts-wrapper${fontsOpen ? " open" : ""}`}
              >
                <button onClick={toggleFontsList}>Font</button>
                <div>
                  <ul>
                    <li>
                      <button className="parens" onClick={setRandomFont}>
                        Random
                      </button>
                    </li>
                    <li>
                      <button
                        className="parens"
                        onClick={toggleFont}
                        data-font="default"
                      >
                        Reset
                      </button>
                    </li>
                  </ul>
                  <ol className="font">
                    <li>
                      <button onClick={toggleFont} data-font="default">
                        Safe Grotesk
                      </button>
                    </li>
                    <li>
                      <button onClick={toggleFont} data-font="cerial">
                        Cerial
                      </button>
                    </li>
                    <li>
                      <button onClick={toggleFont} data-font="axil-mono">
                        Axil Mono
                      </button>
                    </li>
                    <li>
                      <button onClick={toggleFont} data-font="centschbook-bt">
                        CentSchbook BT
                      </button>
                    </li>
                    <li>
                      <button onClick={toggleFont} data-font="arial1by10">
                        Arial1by10
                      </button>
                    </li>
                    <li>
                      <button onClick={toggleFont} data-font="hack">
                        Hack
                      </button>
                    </li>
                    <li>
                      <button onClick={toggleFont} data-font="egizio">
                        Egizio
                      </button>
                    </li>
                    <li>
                      <button onClick={toggleFont} data-font="fragment">
                        Fragment
                      </button>
                    </li>
                  </ol>
                </div>
              </li>
              <li
                ref={colorsList}
                className={`colors-wrapper${colorsOpen ? " open" : ""}`}
              >
                <button onClick={toggleColorsList}>Background</button>
                <div>
                  <ul>
                    <li>
                      <button className="parens" onClick={setRandomBG}>
                        Random
                      </button>
                    </li>
                    <li>
                      <button
                        className="parens"
                        onClick={toggleBG}
                        data-color="white"
                        data-textcolor="#000000"
                      >
                        Reset
                      </button>
                    </li>
                  </ul>
                  <ol className="cols">
                    {colors.map((color) => (
                      <li
                        key={`${
                          color.colortype === "named"
                            ? color.colorname.split(" ").join("").toLowerCase()
                            : color.colorid?.hex
                        }`}
                      >
                        <button
                          onClick={toggleBG}
                          data-color={`${
                            color.colortype === "named"
                              ? color.colorname
                                  .split(" ")
                                  .join("")
                                  .toLowerCase()
                              : color.colorid?.hex
                          }`}
                          data-textcolor={color.lightness}
                        >
                          {color.colorname}
                        </button>
                      </li>
                    ))}
                  </ol>
                </div>
              </li>
              {/* <li>
                <button>Screen</button>
              </li> */}
            </div>
          </ol>
        </nav>
      </div>
      <div
        className={`overlay-connection flex col`}
        // className={`overlay-connection flex col open`}
        style={fullHeightInlineBG}
      >
        <div className="connection-header">
          <button className="mobile button-m-close" onClick={closeAbout}>
            <CgClose />
          </button>
          <h2 className="parens">Connection</h2>
        </div>
        {/* {about.map((child) => (
        <p>{child.children[0].text}</p>
      ))} */}
        {/* <BlockContent blocks={about} /> */}
        <div className="connection-content flex col">
          <PortableText value={about} components={components} />
          {aboutcredits1 && (
            <div className="credits grid-2 text-xs">
              <p className="span-all uc">Credits</p>
              <div>
                <PortableText value={aboutcredits1} components={components} />
              </div>
              <div>
                <PortableText value={aboutcredits2} components={components} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Nav;
