import * as React from "react";
import { useState } from "react";
import { PortableText } from "@portabletext/react";
import "../styles/lightbox.scss";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination, EffectFade } from "swiper";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/effect-fade";
import LightboxMedia from "./media-lightbox";
import { CSSTransition } from "react-transition-group";

const components = {
  marks: {
    link: ({ children, value }) => {
      return (
        <a href={value.href} target="_blank" rel="noreferrer noopener">
          {children}
        </a>
      );
    },
  },
};

const Lightbox = ({
  focusedProject,
  lightboxOpen,
  closeLightbox,
  inlineBG,
  firstSwiper,
  secondSwiper,
  swiperActionMouseOver,
  swiperActionMouseOut,
  descriptionOpen,
  setDescriptionOpen,
}) => {
  let selected;

  if (focusedProject) {
    selected = focusedProject;
  }
  // console.log(selected);

  const selectedDescription = selected._rawDescription;

  // const [descriptionOpen, setDescriptionOpen] = useState(false);

  const toggleDescription = () => {
    setDescriptionOpen(!descriptionOpen);
  };

  const resetLightbox = () => {
    // setFocus(initialFocus);
  };

  // console.log(selected)
  // const headerHeight =
  //   parseFloat(
  //     document.querySelector("header").getBoundingClientRect().height
  //   ) + "px"

  // const lightboxTopMargin = {
  // marginTop: "43px",
  // }

  // console.log(lightboxTopMargin)

  return (
    <>
      {/* <TransitionGroup className="main-lightbox-wrapper"> */}
      <CSSTransition
        in={lightboxOpen}
        appear={true}
        classNames="fade"
        timeout={{
          appear: 750,
          enter: 750,
          exit: 2000,
        }}
        unmountOnExit={true}
        onExited={resetLightbox}
        // key={selected.slug}
      >
        <div
          className="main-lightbox flex-nogap flex-justify col"
          style={inlineBG}
        >
          <LightboxMedia
            project={selected}
            firstSwiper={firstSwiper}
            // setFirstSwiper={setFirstSwiper}
            secondSwiper={secondSwiper}
            // setSecondSwiper={setSecondSwiper}
            // setSwiperCursor={setSwiperCursor}
            // setSwiperCursorText={setSwiperCursorText}
            swiperActionMouseOver={swiperActionMouseOver}
            swiperActionMouseOut={swiperActionMouseOut}
          />
          <div
            className={`flex-nogap col lightbox-detail ${selected.slug.current}`}
          >
            <div
              className={`lightbox-description lb-grid${
                descriptionOpen ? " open" : ""
              }`}
              style={inlineBG}
            >
              <PortableText
                value={selectedDescription}
                components={components}
              />
            </div>
            <div className="lightbox-meta lb-grid row">
              <h2>{selected.name}</h2>
              <button className="parens" onClick={toggleDescription}>
                {!descriptionOpen ? "Read more..." : "Read less..."}
              </button>
              <button className="parens" onClick={closeLightbox}>
                Close
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
      {/* </TransitionGroup> */}
    </>
  );
};

export default Lightbox;
