import React from "react";

const DigestItem = ({ item }) => {
  // console.log(item);
  // var he = require("he");

  const dtype = item?.class;
  const isImage = dtype === "Image";
  const isText = dtype === "Text";

  const dcredit = item?.title?.split(" – ")[0];
  const dtitle = item?.title?.split(" – ")[1];
  // const rawtitle = item?.title?.split(" – ")[1];
  // const dtitle = he?.decode(rawtitle);
  const dyear = item?.description?.split("\n___")[0];
  const ddescription = item?.description?.split("___\n")[1];
  const dcontent = item?.content;
  const dsource = item?.description?.split("___\n")[3];

  return (
    <div className="digest-item flex col">
      {dcredit && <h4>{dcredit}</h4>}
      {dtitle && (
        <div>
          <p className="uc">Reference</p>
          {dtitle}, {dyear}
        </div>
      )}
      {isImage && <img src={item?.image?.original?.url} />}
      {isText && <pre>{dcontent}</pre>}
      {/* {item?.content != "" ? item?.content : ""} */}
      {ddescription && <span className="pre-wrap">{ddescription}</span>}
      {/* <pre>{item?.ddesc != "" ? ddesc : ""}</pre> */}
      {dsource && (
        <a
          href={dsource}
          target="_blank"
          rel="noreferrer noopener"
          className="parens-xs"
        >
          See more...
        </a>
      )}
    </div>
  );
};

export default DigestItem;
