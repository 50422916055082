import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { gsap } from "gsap";
// import data from "../data/data-projects.js";
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo";
import Nav from "../components/nav";
import OverlayIndex from "../components/overlayIndex";
import Header from "../components/header";
import Lightbox from "../components/lightbox";
import Digest from "../components/digest";
import Projects from "../components/projects";
import SwiperCursor from "../components/cursor";
import Loading from "../components/loading";
// import digestItems from "../data/data-digest";
// import WaveButton from "../components/waveButton";
import "../styles/global.scss";
import "../styles/index.scss";

// console.log(digestItems);

const IndexPage = () => {
  // const [menuOpen, setMenuOpen] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [linksLoaded, setLinksLoaded] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [indexOpen, setIndexOpen] = useState(false);
  // const [indexData, setIndexData] = useState(null);
  // const [indexData, setIndexData] = useState("project");
  // const [indexOpen, setIndexOpen] = useState(true);
  const [font, setFont] = useState("default");
  const [bg, setBG] = useState({
    color: "white",
    text: "#000000",
  });
  // const [firstSwiper, setFirstSwiper] = useState(null);
  // const [secondSwiper, setSecondSwiper] = useState(null);
  const firstSwiper = useRef(null);
  const secondSwiper = useRef(null);

  const [lightboxOpen, setLightboxOpen] = useState(false);

  const initialFocus = {
    client: "",
    credits1: "",
    credits2: "",
    description: "",
    id: "",
    images: [],
    mediatype: "",
    mediaurl: "",
    name: "",
    projecttype: "",
    slug: {
      current: "",
    },
    video: "",
    year: "",
  };
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [focusedProject, setFocus] = useState(initialFocus);

  const [maxHeight, setMaxHeight] = useState();
  const [digestOpen, setDigestOpen] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [digestMaxHeight, setDigestMaxHeight] = useState();
  const [digestAway, setDigestAway] = useState(false);

  // console.log(digestAway);

  const toggleDigest = () => {
    // setMenuOpen(false);
    setDigestOpen(!digestOpen);
  };

  const menuToggleDigest = () => {
    setMenuOpen(false);
    setAboutOpen(false);
    setDigestOpen(!digestOpen);
    // setTimeout(() => {
    // }, 250);
  };

  useEffect(() => {
    setTimeout(() => {
      setLinksLoaded(true);
    }, 3250);
  }, []);

  // console.log("On load / Index data: " + indexData);
  // console.log("About open: " + aboutOpen);
  // console.log("Menu open: " + menuOpen);

  const data = useStaticQuery(graphql`
    query {
      allSanityProject {
        nodes {
          name
          _id
          slug {
            current
          }
          year
          client
          mediatype
          mediagallery2 {
            ... on SanityImageWithAlt {
              _key
              _type
              alt
              asset {
                gatsbyImageData(
                  placeholder: NONE
                  fit: CLIP
                  layout: FULL_WIDTH
                )
              }
            }
            ... on SanityVideo {
              _key
              _type
              videoaspect
              videofile {
                asset {
                  url
                  extension
                }
              }
            }
          }
          mediawebsite1 {
            ... on SanityWebsite {
              _key
              _type
              url
              landscape
            }
            ... on SanityWebsitevideo {
              _key
              _type
              asset {
                url
                extension
              }
            }
          }
          mediawebsite2 {
            ... on SanityWebsite {
              _key
              _type
              url
            }
            ... on SanityWebsitevideo {
              _key
              _type
              asset {
                url
                extension
              }
            }
          }
          credits1 {
            _key
            creditname
            crediturl
          }
          credits2 {
            _key
            creditname
            crediturl
          }
          projecttype
          _rawDescription
        }
      }
      sanityInfo {
        _rawAbout
        _rawAboutcredits1
        _rawAboutcredits2
        about {
          children {
            text
          }
        }
        digest
        bgcolors {
          colorname
          colortype
          colorid {
            hex
          }
          lightness
        }
      }
    }
  `);

  const [mobileState, setMobileState] = useState(null);

  useEffect(() => {
    // console.log(mobileState);
    // console.log(mobile.matches);
    const mobile = window.matchMedia(`(max-width: 800px)`);
    setMobileState(mobile.matches);
    setMaxHeight(window.innerHeight + "px");
    setDigestMaxHeight(window.innerHeight - 32 + "px");

    function updateMediaSize() {
      setMobileState(mobile.matches);
      setDigestMaxHeight(window.innerHeight - 32 + "px");
      setMaxHeight(window.innerHeight + "px");
    }

    if (window.addEventListener) {
      window.addEventListener("resize", updateMediaSize);
      return function cleanup() {
        window.removeEventListener("resize", updateMediaSize);
      };
    } else {
      // backwards compatibility
      window.addEventListener(updateMediaSize);
      return function cleanup() {
        window.removeEventListener(updateMediaSize);
      };
    }
  }, []);

  useEffect(() => {
    setDigestOpen(!mobileState);
  }, [mobileState]);

  // console.log(isMobile);
  // setMobileState(isMobile.matches);

  // TO DO ------------------------------------------------------------
  // const digestSlug = data.sanityInfo.digest.split("/")[4];
  // const digestSlug = "digest-api-sample-2";
  const digestSlug = data.sanityInfo.digest.split("/")[4];
  // console.log(data.sanityInfo.digest.split("/")[4]);
  // console.log(
  //   `https://api.are.na/v2/channels/${
  //     data.sanityInfo.digest.split("/")[4]
  //   }/contents?direction=desc`
  // );
  const [digestContent, setDigestContent] = useState([]);
  const [digestReady, setDigestReady] = useState([]);

  useEffect(() => {
    fetch(
      `https://api.are.na/v2/channels/${digestSlug}/contents?direction=desc`
    )
      .then((response) => response.json())
      .then((json) => {
        setDigestContent(json.contents);
        setDigestReady(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // ------------------------------------------------------------------

  const projects = data.allSanityProject.nodes;
  // console.log(projects);
  const [sortBy, setSortBy] = useState("year");
  const [projectData, setProjectData] = useState(
    [...projects].sort((a, b) => b.year - a.year)
  );

  // const about = data.sanityInfo.about;
  const about = data.sanityInfo._rawAbout;
  const aboutcredits1 = data.sanityInfo._rawAboutcredits1;
  const aboutcredits2 = data.sanityInfo._rawAboutcredits2;

  const colors = data.sanityInfo.bgcolors;
  const colorValues = colors.map((color) =>
    color.colortype === "named"
      ? color.colorname.split(" ").join("").toLowerCase()
      : color.colorid?.hex
  );

  const fonts = [
    "default",
    "axil-mono",
    "centschbook-bt",
    "hack",
    "cerial",
    "arial1by10",
    "egizio",
    "fragment",
  ];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setAboutOpen(false);
  };

  const toggleAbout = () => {
    setAboutOpen(!aboutOpen);

    // if (mobileState) {
    //   setMenuOpen(false);
    // }
  };

  const closeAbout = () => {
    setAboutOpen(false);
  };

  // console.log("Index open = " + indexOpen);

  const openIndex = (e) => {
    toggleMenu();
    // setIndexData(e.target.dataset.indexdata);
    setIndexOpen(!indexOpen);
    setDigestOpen(false);
    // console.log("Open index / Index data: " + indexData);
    setLightboxOpen(false);
  };

  // const resetIndexData = (e) => {
  // setIndexData(e.target.dataset.indexdata);
  // console.log("Reset data / Index data: " + indexData);
  // };

  const toggleFont = (e) => {
    setFont(e.target.dataset.font);
  };

  const toggleBG = (e) => {
    setBG({
      color: e.target.dataset.color,
      text: e.target.dataset.textcolor,
    });
  };

  const inlineBG = {
    backgroundColor: bg.color,
    transitionProperty: "all",
    transitionDuration: "300ms",
    transitionTimingFunction: "ease-in-out",
    transitionDelay: "0",
  };

  const digestStyle = {
    maxHeight: digestMaxHeight,
    backgroundColor: bg.color,
  };

  const fullHeight = {
    height: maxHeight,
  };

  const fullHeightInlineBG = {
    height: maxHeight,
    backgroundColor: bg.color,
    transitionProperty: "all",
    transitionDuration: "300ms",
    transitionTimingFunction: "ease-in-out",
    transitionDelay: "0",
  };

  const inlineBGGradient = {
    background: `linear-gradient(180deg, ${bg.color} 80%, rgba(255,0,0,0) 100%)
`,
    //     background: `linear-gradient(180deg, ${bg.color} 60%, rgba(255,0,0,0) 100%)
    // `,
    // color: bg.text,
    transitionProperty: "all",
    transitionDuration: "300ms",
    transitionTimingFunction: "ease-in-out",
    transitionDelay: "0",
  };

  const setRandomBG = (e) => {
    const randomID = Math.floor(Math.random() * colors.length);
    const randomCol = colorValues[randomID];
    const randomFontCol = colors[randomID].lightness;
    setBG({
      color: randomCol,
      text: randomFontCol,
    });
  };

  // const fontButtons = document.querySelectorAll(".fonts-wrapper div button");
  // const fontArray = Array.from(fontButtons);
  // console.log(fontButtons);
  // console.log(fontArray);
  // const fontsData = fontArray.map((font) => {
  //   return font.dataset.font;
  // });
  // console.log(fontsData);

  const setRandomFont = (e) => {
    const randomFont = fonts[Math.floor(Math.random() * fonts.length)];
    setFont(randomFont);
  };

  // console.log("Selected: " + focusedProject);

  // console.log("Lightbox open: " + lightboxOpen);

  const toggleLightbox = (e) => {
    const action = e.target.dataset.action;
    if (action === "open") {
      setLightboxOpen(true);
      setFocus(parseInt(e.target.dataset.id));
      setIndexOpen(false);
      setMenuOpen(false);
      setAboutOpen(false);
    } else {
      // closeLightbox();
      setLightboxOpen(false);
    }
  };

  const closeLightbox = () => {
    // console.log("Close lightbox");
    // setFocus(initialFocus);
    setLightboxOpen(false);
    // const lightbox = document.querySelector(".main-lightbox");
    // lightbox.classList.add("fade-exit-active");
    // setTimeout(() => {
    //   setFocus(null);
    //   // lightbox.classList.remove("fade-exit-active");
    // }, 500);
    // console.log("Lightbox open: " + lightboxOpen);
  };

  // Update project sort ------------------------------------

  const [randomSort, setRandomSort] = useState();

  const updateSort = (e) => {
    const newSort = e.target.dataset.sort;
    setSortBy(newSort);

    if (newSort === "year") {
      setProjectData([...projects].sort((a, b) => b.year - a.year));
    } else if (newSort === "name") {
      setProjectData(
        [...projects].sort((a, b) => a.name.localeCompare(b.name))
      );
    } else if (newSort === "random") {
      setRandomSort(!randomSort);
      setProjectData([...gsap.utils.shuffle(projectData)]);
    }

    if (mobileState) {
      setMenuOpen(false);
    }
  };

  // Video playback -----------------------------------------

  useEffect(() => {
    const videos = document.querySelectorAll("video");
    videos.forEach((video) => {
      video.pause();
      setTimeout(() => {
        video.play();
      }, 2500);
    });
  }, []);

  // useLayoutEffect(() => {
  //   if (sortBy === "year") {
  //     setProjectData([...projects].sort((a, b) => b.year - a.year));
  //   } else if (sortBy === "name") {
  //     setProjectData(
  //       [...projects].sort((a, b) => a.name.localeCompare(b.name))
  //     );
  //     // } else if (sortBy === "random") {
  //     //   setRandomSort(!randomSort);
  //   }
  // }, [sortBy]);

  // Random project sort ------------------------------------

  // useLayoutEffect(() => {
  //   setProjectData([...gsap.utils.shuffle(projectData)]);
  // }, [randomSort]);

  // Swiper cursor ------------------------------------------

  const [swiperCursorText, setSwiperCursorText] = useState();

  const swiperActionMouseOver = (e) => {
    setSwiperCursorText(e.target.dataset.action);
    // console.log(swiperCursorText);
  };

  const swiperActionMouseOut = (e) => {
    setSwiperCursorText(null);
  };

  // Loading... ---------------------------------------------

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 2000);
  }, []);

  // Render components --------------------------------------

  return (
    <>
      <Seo />
      <div
        className={`site-wrapper${indexOpen ? " index-open" : ""}${
          aboutOpen ? " about-open" : ""
        }${linksLoaded ? " links-loaded" : ""}${
          pageLoaded ? " loaded" : " loading"
        }${dragging ? " dragging" : ""}`}
        // style={backgroundColor: {bg}}
        style={inlineBG}
        // data-bg={bg}
        data-textcolor={bg.text}
        data-font={font}
      >
        <Loading pageLoaded={pageLoaded} />
        <Nav
          fullHeightInlineBG={fullHeightInlineBG}
          fullHeight={fullHeight}
          updateSort={updateSort}
          randomsort={randomSort}
          setRandomSort={setRandomSort}
          menuOpen={menuOpen}
          about={about}
          aboutcredits1={aboutcredits1}
          aboutcredits2={aboutcredits2}
          aboutOpen={aboutOpen}
          toggleMenu={toggleMenu}
          toggleAbout={toggleAbout}
          menuToggleDigest={menuToggleDigest}
          closeAbout={closeAbout}
          toggleFont={toggleFont}
          toggleBG={toggleBG}
          inlineBG={inlineBG}
          colors={colors}
          fonts={fonts}
          setRandomBG={setRandomBG}
          setRandomFont={setRandomFont}
          openIndex={openIndex}
        />
        <Header
          pageLoaded={pageLoaded}
          menuOpen={menuOpen}
          indexOpen={indexOpen}
          toggleMenu={toggleMenu}
        />
        <Digest
          digestMaxHeight={digestMaxHeight}
          mobileState={mobileState}
          pageLoaded={pageLoaded}
          digestReady={digestReady}
          digestAway={digestAway}
          digestContent={digestContent}
          dragging={dragging}
          setDragging={setDragging}
          digestStyle={digestStyle}
          digestOpen={digestOpen}
          // setDigestOpen={setDigestOpen}
          toggleDigest={toggleDigest}
        />
        <main className="flex-nogap main-home">
          <OverlayIndex
            projectData={projectData}
            // digestItems={digestItems}
            setIndexOpen={setIndexOpen}
            indexOpen={indexOpen}
            // indexData={indexData}
            inlineBG={inlineBG}
            updateSort={updateSort}
            randomSort={randomSort}
            setRandomSort={setRandomSort}
            digestContent={digestContent}
          />
          <Lightbox
            descriptionOpen={descriptionOpen}
            setDescriptionOpen={setDescriptionOpen}
            inlineBG={inlineBG}
            projectData={projectData}
            focusedProject={focusedProject}
            lightboxOpen={lightboxOpen}
            closeLightbox={closeLightbox}
            toggleLightbox={toggleLightbox}
            setFocus={setFocus}
            initialFocus={initialFocus}
            firstSwiper={firstSwiper}
            // setFirstSwiper={setFirstSwiper}
            secondSwiper={secondSwiper}
            // setSecondSwiper={setSecondSwiper}
            swiperActionMouseOver={swiperActionMouseOver}
            swiperActionMouseOut={swiperActionMouseOut}
            // setSwiperCursor={setSwiperCursor}
            // setSwiperCursorText={setSwiperCursorText}
          />
          <Projects
            randomSort={randomSort}
            pageLoaded={pageLoaded}
            setDigestOpen={setDigestOpen}
            setDigestAway={setDigestAway}
            inlineBG={inlineBG}
            inlineBGGradient={inlineBGGradient}
            sortBy={sortBy}
            projectData={projectData}
            toggleLightbox={toggleLightbox}
            descriptionOpen={descriptionOpen}
            setDescriptionOpen={setDescriptionOpen}
            // openLightbox={openLightbox}
            closeLightbox={closeLightbox}
            // changeLightbox={changeLightbox}
            lightboxOpen={lightboxOpen}
            focusedProject={focusedProject}
            // mobileState={mobileState}
            setLightboxOpen={setLightboxOpen}
            setFocus={setFocus}
            setIndexOpen={setIndexOpen}
            setMenuOpen={setMenuOpen}
            setAboutOpen={setAboutOpen}
            firstSwiper={firstSwiper}
            // setFirstSwiper={setFirstSwiper}
            secondSwiper={secondSwiper}
            // setSecondSwiper={setSecondSwiper}
            // setSwiperCursor={setSwiperCursor}
            // setSwiperCursorText={setSwiperCursorText}
            swiperActionMouseOver={swiperActionMouseOver}
            swiperActionMouseOut={swiperActionMouseOut}
          />
        </main>
        <SwiperCursor swiperCursorText={swiperCursorText} />
      </div>
    </>
  );
};
export default IndexPage;
