// import * as React from "react";
import React, { useRef } from "react";
import Project from "./project";
import "../styles/projects.scss";
import { gsap } from "gsap";
import { useEffect } from "react";

const Projects = ({
  randomSort,
  sortBy,
  pageLoaded,
  setDigestOpen,
  setDigestAway,
  projectData,
  toggleLightbox,
  lightboxOpen,
  hoverWaveStart,
  hoverWaveEnd,
  focusedProject,
  closeLightbox,
  mobileState,
  setLightboxOpen,
  setFocus,
  setIndexOpen,
  setMenuOpen,
  setAboutOpen,
  firstSwiper,
  secondSwiper,
  swiperActionMouseOver,
  swiperActionMouseOut,
  descriptionOpen,
  setDescriptionOpen,
}) => {
  const projectsRef = useRef();
  const projectsSelector = gsap.utils.selector(projectsRef);

  // Load in projects ---------------------------------------

  // const projectstl = gsap.timeline();

  useEffect(() => {
    // projectstl.to(projectsSelector("h2, .index-project > *"), {
    //   opacity: 1,
    //   duration: 0.5,
    //   delay: 2,
    //   // stagger: 0.1,
    // });
    // projectstl.to(
    //   ".index-project .project-media",
    //   {
    //     opacity: 1,
    //     duration: 0.5,
    //     delay: 2.75,
    //     // stagger: 0.5,
    //   },
    //   "<"
    // );
    // projectstl.to(".index-project .project-media", {
    //   filter: "blur(0px)",
    //   duration: 0.5,
    //   delay: 0.5,
    //   // stagger: 0.5,
    // });
  }, [pageLoaded]);

  // Animate on sort ----------------------------------------

  // useEffect(() => {
  //   gsap.from(projectsSelector(".index-project"), {
  //     opacity: 0,
  //     duration: 0.5,
  //     // delay: 1,
  //     // y: 50,
  //     // stagger: 0.5,
  //   });
  // }, [sortBy]);

  useEffect(() => {
    gsap.from(projectsSelector(".index-project"), {
      opacity: 0,
      duration: 0.5,
      // delay: 1,
      // y: 50,
      // stagger: 0.5,
    });
  }, [randomSort, sortBy]);

  // Randomise ----------------------------------------------

  // const randomise = () => {
  //   return gsap.utils.shuffle(projectData);
  // };

  // randomise();

  // Custom cursor ------------------------------------------

  return (
    <div
      ref={projectsRef}
      className={`main-index flex col${pageLoaded && " loaded"}`}
      id="main-index"
    >
      <h2 className={`blend`}>Projects</h2>
      {/* <div className="h2-wrapper">
      </div> */}
      <div className={`projects-content-wrapper${pageLoaded && " loaded"}`}>
        {projectData.map((project, index) => (
          <Project
            descriptionOpen={descriptionOpen}
            setDescriptionOpen={setDescriptionOpen}
            key={project._id}
            setDigestOpen={setDigestOpen}
            setDigestAway={setDigestAway}
            firstSwiper={firstSwiper}
            // setFirstSwiper={setFirstSwiper}
            secondSwiper={secondSwiper}
            // setSecondSwiper={setSecondSwiper}
            project={project}
            index={index}
            toggleLightbox={toggleLightbox}
            // openLightbox={openLightbox}
            closeLightbox={closeLightbox}
            // changeLightbox={changeLightbox}
            lightboxOpen={lightboxOpen}
            hoverWaveStart={hoverWaveStart}
            hoverWaveEnd={hoverWaveEnd}
            focusedProject={focusedProject}
            mobileState={mobileState}
            setLightboxOpen={setLightboxOpen}
            setFocus={setFocus}
            setIndexOpen={setIndexOpen}
            setMenuOpen={setMenuOpen}
            setAboutOpen={setAboutOpen}
            // setSwiperCursor={setSwiperCursor}
            // setSwiperCursorText={setSwiperCursorText}
            swiperActionMouseOver={swiperActionMouseOver}
            swiperActionMouseOut={swiperActionMouseOut}
          />
        ))}
      </div>
    </div>
  );
};

export default Projects;

// const projects = [
//   {
//     id: "1",
//     title: "FHRAS 'Make Good",
//     year: "2020",
//     mediatype: "image",
//     media: [
//       "1-fhras-1.jpg",
//       "2-recount-1.jpg",
//       "3-verner-1.jpg",
//       "4-sibling-1.jpg",
//     ],
//     credits1: ["Jessica Wood", "Nina Tory-Henderson"],
//     credits2: ["Melbourne Design Week", "Yarra City Arts"],
//     type: ["Exhibition Identity", "Room publication", "Didactics"],
//     description:
//       "Description sagittis dolor id est auctor, at tristique elit aliquam. Donec arcu eros, dictum ut nibh nec, ultricies commodo quam. Pellentesque varius pulvinar malesuada. Sed gravida sapien vulputate tortor gravida, quis interdum libero sollicitudin. Integer turpis ipsum, cursus quis iaculis eget, lobortis ut ante. Vestibulum vestibulum ornare turpis, non cursus massa luctus at.",
//   },
//   {
//     id: "2",
//     title: "A recount of conversations that were mostly about books",
//     year: "2022",
//     mediatype: "image",
//     media: "2-recount-1.jpg",
//     credits1: ["Michael Bojkowski", "Kim Mumm Hansen", "Nina Gibbes"],
//     credits2: ["RMIT", "Communication Design Studio"],
//     type: ["Publication", "Writing", "Curration", "Workshop"],
//     description:
//       "Description non ornare lacus. Morbi laoreet odio eros, nec ornare nunc interdum eu. Duis blandit non orci vel bibendum. Vestibulum dignissim magna vel mollis venenatis. Aenean ut ligula sit amet tortor sagittis pretium in sed tortor. Nulla tempor vehicula enim eu porttitor. Aenean congue lacus in risus placerat interdum.",
//   },
//   {
//     id: "3",
//     title: "Verner Webstore",
//     year: "2020",
//     mediatype: "image",
//     media: "3-verner-1.jpg",
//     credits1: ["Nicole Ho", "@talkingmannequin", "@earnestraw"],
//     credits2: ["Ingrid Verner", "Gian Manik"],
//     type: ["Website", "E-Commerce", "Fashion"],
//     description:
//       "Description eu semper quam. Aliquam consectetur, ipsum sed varius venenatis, augue eros bibendum sapien, nec ultricies dui nulla vel nisl. Sed nunc sem, bibendum sit amet vulputate sed, gravida a lectus. In ut fermentum mi. Nam ac turpis mauris. Sed posuere, elit a malesuada.",
//   },
//   {
//     id: "4",
//     title: "New Agency: Owning Your Own Future",
//     year: "2018",
//     mediatype: "image",
//     media: "4-sibling-1.jpg",
//     credits1: [
//       "Sibling Architecture",
//       "RMIT Design Hub",
//       "Creative Victoria Creators Fund",
//     ],
//     credits2: [
//       "Kate Rhodes",
//       "Nella Themelios",
//       "Erik North",
//       "Tim McLeod",
//       "Layla Cluer",
//       "Michaela Bear",
//       "Ari Sharp",
//       "Simon Maisch",
//     ],
//     type: [
//       "Exhibition Identity",
//       "Publication",
//       "Exhibition Design",
//       "Animation",
//     ],
//     description:
//       "Description egestas dapibus suscipit. In sodales cursus ipsum vel dapibus. Donec quis eros sit amet nulla pharetra blandit. Etiam ultrices massa odio, at cursus ante vulputate ut. Fusce laoreet ullamcorper laoreet. Quisque pretium tincidunt rhoncus. Duis risus eros, ornare non neque sit amet, consectetur tempus purus.",
//   },
// ];
