import * as React from "react";
import { useRef, useEffect } from "react";

const SwiperCursor = ({ swiperCursorText }) => {
  const swiperActionCursor = useRef(null);
  // const [cursorY, setCursorY] = useState(null);
  // const [cursorX, setCursorX] = useState(null);

  let cursorTop, cursorLeft;

  const swiperActionMousemove = (e) => {
    cursorTop = e.pageY + "px";
    cursorLeft = e.pageX + "px";
    swiperActionCursor.current.style.top = cursorTop;
    swiperActionCursor.current.style.left = cursorLeft;
  };

  // const cursorCoords = {
  //   top: cursorTop,
  //   left: cursorLeft,
  // };

  useEffect(() => {
    document.addEventListener("mousemove", swiperActionMousemove);
  });

  return (
    <>
      <div
        // style={cursorCoords}
        ref={swiperActionCursor}
        className="swiper-action-cursor blend"
      >
        {swiperCursorText}
      </div>
    </>
  );
};

export default SwiperCursor;
