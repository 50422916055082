import React from "react";

const OverlayIndexSort = ({ indexData, inlineBG, updateSort }) => {
  return (
    <div
      className={`index-sort text-xs flex-nogap desktop${
        indexData === "projects" ? " active" : ""
      }`}
      style={inlineBG}
    >
      <button data-sort="random" onClick={updateSort}>
        Random
      </button>
      <button data-sort="name" onClick={updateSort}>
        A&mdash;B
      </button>
      <button data-sort="year" onClick={updateSort}>
        Latest
      </button>
    </div>
  );
};

export default OverlayIndexSort;
